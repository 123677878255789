<template>
  <div class="input-toggle" :class="{ 'input-toggle--checked': value, 'input-toggle--disabled': disabled }">
    <label class="label">
      <input
        class="checkbox"
        type="checkbox"
        :name="name"
        :checked="value"
        @change="emit('change')"
        :disabled="disabled"
      />

      <slot name="before" />
      <span class="slider" />
      <slot name="default" />
    </label>
  </div>
</template>

<script setup lang="ts">
  interface IProps {
    value: boolean;
    name: string;
    disabled?: boolean;
  }
  const { value, name, disabled = false } = defineProps<IProps>();
  const emit = defineEmits(['change']);
</script>

<style src="~/assets/styles/components/form/input/toggle.scss" lang="scss" />
